import React from "react";
import { data } from "../../data/Data";
import { FaArrowCircleRight } from "react-icons/fa";
import Reveal3 from "../Animations/Reveal3";

function Project() {
  //   console.log(data);

  return (
    <>
      <Reveal3>
        <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 gap-8 p-8">
          {data.map((item, index) => (
            <div
              key={index}
              className="bg-[#def2f1] shadow-lg hover:scale-105 ease-out duration-300 p-4 rounded-lg shadow-[#2b7a78]"
            >
              <h1 className="text-2xl lg:text-3xl text-[#2b7a78] font-bold my-2">
                {item.name}
              </h1>
              <p className="italic text-gray-500 text-sm my-2">{item.date}</p>
              <p className="text-md lg:text-xl my-8">{item.text}</p>
              <p className="text-md lg:text-lg">
                <span className="font-bold text-[#3aafa9]">Role: </span>
                {item.role}
              </p>
              {/* Tech used */}
              <div className="my-8 flex flex-wrap">
                {item.image1 && (
                  <img
                    src={item.image1}
                    alt={item.name}
                    className="w-[30px] lg:w-[40px] m-2"
                  />
                )}
                {item.image2 && (
                  <img
                    src={item.image2}
                    alt={item.name}
                    className="w-[30px] lg:w-[40px] m-2"
                  />
                )}
                {item.image3 && (
                  <img
                    src={item.image3}
                    alt={item.name}
                    className="w-[30px] lg:w-[40px] m-2"
                  />
                )}
                {item.image4 && (
                  <img
                    src={item.image4}
                    alt={item.name}
                    className="w-[30px] lg:w-[40px] m-2"
                  />
                )}
                {item.image5 && (
                  <img
                    src={item.image5}
                    alt={item.name}
                    className="w-[30px] lg:w-[40px] m-2"
                  />
                )}
                {item.image6 && (
                  <img
                    src={item.image6}
                    alt={item.name}
                    className="w-[30px] lg:w-[40px] m-2"
                  />
                )}
                {item.image7 && (
                  <img
                    src={item.image7}
                    alt={item.name}
                    className="w-[30px] lg:w-[40px] m-2"
                  />
                )}
                {item.image8 && (
                  <img
                    src={item.image8}
                    alt={item.name}
                    className="w-[30px] lg:w-[40px] m-2"
                  />
                )}
                {item.image9 && (
                  <img
                    src={item.image9}
                    alt={item.name}
                    className="w-[30px] lg:w-[40px] m-2"
                  />
                )}
                {item.image10 && (
                  <img
                    src={item.image10}
                    alt={item.name}
                    className="w-[30px] lg:w-[40px] m-2"
                  />
                )}
                {item.image11 && (
                  <img
                    src={item.image11}
                    alt={item.name}
                    className="w-[30px] lg:w-[40px] m-2"
                  />
                )}
              </div>
              <div className="flex items-center justify-center hover:text-[#7a2b2d] text-[#2b7a78] cursor-pointer">
                <a
                  href={item.link}
                  className="text-md lg:text-lg mr-1"
                  target="target_blank"
                >
                  Link to project
                </a>
                <FaArrowCircleRight size={24} />
              </div>
            </div>
          ))}
        </div>
      </Reveal3>
    </>
  );
}

export default Project;
