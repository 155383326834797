import React from "react";
import { Button } from "react-scroll";
import { FaArrowUp } from "react-icons/fa";

const Top = () => {
  return (
    <div className="flex p-2 md:p-6 fixed right-0 bottom-0 z-200">
      <Button to="navbar" smooth={true} duration={500} className="p-4 rounded-full text-[#feffff] bg-[#2b7a78] border-[#2b7a78] hover:bg-[#3aafa9] hover:text-[#2b7a78] border-2 ease-in duration-100">
        <FaArrowUp size={25} />
      </Button>
    </div>
  );
};

export default Top;
