import React from "react";

import { Route, Routes } from "react-router-dom";
import Home from "./components/Home/Home";
import Education from "./components/Education/Education";
import Navbar from "./components/Navbar";
import Experience from "./components/Experience/Experience";
import Projects from "./components/Projects/Projects";
import Contacts from "./components/Contacts/Contacts";
import Open from "./components/Open/Open";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/education" element={<Education />} />
        <Route path="/experience" element={<Experience />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/open" element={<Open />} />
        <Route path="/contacts" element={<Contacts />} />
      </Routes>
    </>
  );
}

export default App;
