import React from "react";
import hero from "../../assets/contactHero.png";
import { IoIosLink, IoLogoGithub } from "react-icons/io";
import { FaGoogle, FaLinkedinIn, FaXTwitter } from "react-icons/fa6";
import AnimatedPage from "../Animations/AnimatedPage";

const ConHero = () => {
  return (
    <AnimatedPage>
      {/* parent div */}
      <div className="w-full h-screen">
        {/* container div */}
        <div className="max-w-[1730px] w-full h-full mx-auto">
          {/* hero */}
          <div className="grid grid-cols-1 lg:grid-cols-2 items-center my-20 mx-8 sm:mx-auto">
            <div className="col-span-1 m-auto sm:px-0 rounded-3xl shadow-xl shadow-[#17252a] hover:scale-105 ease-out duration-300">
              <img src={hero} alt="/" className="rounded-3xl p-4 w-[500px]" />
            </div>
            <div>
              <h1 className="text-center font-semibold my-8 text-4xl sm:text-5xl lg:text-6xl text-[#def2f1]">
                Contact Me
              </h1>
              <p className="text-center my-8 text-xl lg:text-3xl text-[#17252a] tracking-wider px-8 lg:px-0">
                If you want to get in touch with me please do not hesitate to
                contact me through the below social platforms
              </p>
              <div className="flex justify-center items-center py-8">
                {/* github */}
                <div className="pr-2">
                  <a
                    href="https://github.com/nayonacademy"
                    target="target_blank"
                  >
                    <div className="p-2 rounded-full text-white bg-black border-none hover:bg-[#2b7a78] ease-in-out duration-300">
                      <IoLogoGithub size={33} />
                    </div>
                  </a>
                </div>
                {/* linkedin */}
                <div className="px-2">
                  <a
                    href="https://www.linkedin.com/in/shariful-islam-tech/"
                    target="target_blank"
                  >
                    <div className="p-2 rounded-full text-white bg-[#0077B5] border-none hover:bg-[#2b7a78] ease-in-out duration-300">
                      <FaLinkedinIn size={33} />
                    </div>
                  </a>
                </div>
                {/* x */}
                <div className="px-2">
                  <a
                    href="https://twitter.com/nayonacademy"
                    target="target_blank"
                  >
                    <div className="p-2 rounded-full text-white bg-black border-none hover:bg-[#2b7a78] ease-in-out duration-300">
                      <FaXTwitter size={33} />
                    </div>
                  </a>
                </div>
                {/* gmail */}
                <div className="px-2">
                  <a href="mailto:si.nayon@gmail.com" target="target_blank">
                    <div className="p-2 rounded-full text-white bg-[#ea4435] border-none hover:bg-[#2b7a78] ease-in-out duration-300">
                      <FaGoogle size={33} />
                    </div>
                  </a>
                </div>
                {/* nayon site */}
                <div className="pl-2">
                  <a href="https://nayon.net" target="target_blank">
                    <div className="p-2 rounded-full text-white bg-[#67de50] border-none hover:bg-[#2b7a78] ease-in-out duration-300">
                      <IoIosLink size={33} />
                    </div>
                  </a>
                </div>
              </div>
              {/* Button */}
              <div className="flex justify-center items-center">
                <a
                  href="https://drive.google.com/file/d/1x61GJlPCyXb-JLasUBGAMN8M5cQ1v4n8/view"
                  target="target_blank"
                >
                  <button className="my-4 border-2 duration-300 bg-[#17252a] text-[#feffff] py-2 px-4 rounded-md hover:bg-[#3aafa9] hover:border-2 border-[#17252a] hover:text-[#17252a]">
                    <div className="flex items-center">
                      <span className="text-xl px-2 tracking-widest">
                        See My Resume
                      </span>
                    </div>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
};

export default ConHero;
