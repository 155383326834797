import React, { useState } from "react";
import bullet from "../../assets/bullet.png";
import Reveal from "../../components/Animations/Reveal";
import Reveal2 from "../../components/Animations/Reveal2";

const Skills = (props) => {
  const [skill1, setSkill1] = useState(true);
  const [skill2, setSkill2] = useState(true);
  const [skill3, setSkill3] = useState(true);
  const [skill4, setSkill4] = useState(true);
  const [skill5, setSkill5] = useState(true);
  const [skill6, setSkill6] = useState(true);
  const [skill7, setSkill7] = useState(true);
  const [skill8, setSkill8] = useState(true);
  const [desc1, setDesc1] = useState(true);
  const [desc2, setDesc2] = useState(true);
  const [desc3, setDesc3] = useState(true);
  const [desc4, setDesc4] = useState(true);

  const handleSkill1 = () => {
    setSkill1(!skill1);
  };
  const handleSkill2 = () => {
    setSkill2(!skill2);
  };
  const handleSkill3 = () => {
    setSkill3(!skill3);
  };
  const handleSkill4 = () => {
    setSkill4(!skill4);
  };
  const handleSkill5 = () => {
    setSkill5(!skill5);
  };
  const handleSkill6 = () => {
    setSkill6(!skill6);
  };
  const handleSkill7 = () => {
    setSkill7(!skill7);
  };
  const handleSkill8 = () => {
    setSkill8(!skill8);
  };
  const handlleAllSkills = () => {
    setSkill1(true);
    setSkill2(true);
    setSkill3(true);
    setSkill4(true);
    setSkill5(true);
    setSkill6(true);
    setSkill7(true);
    setSkill8(true);
    setDesc1(true);
    setDesc2(true);
    setDesc3(true);
    setDesc4(true);
  };

  return (
    // parent div
    <div className="w-full h-screen my-60 sm:my-0">
      {/* container div */}
      <div className="max-w-[1730px] w-full h-full flex justify-center items-center mx-auto">
        {/* grid container */}
        <div className="flex mx-4 justify-center items-center w-full h-full">
          {/* grid */}
          <div className="grid grid-cols-2 w-full h-full p-2 sm:p-4">
            {/* grid row 1*/}
            <div className="col-span-2 text-center px-2">
              <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold my-4 lg:my-8">
                {props.mainHeading}
              </h1>
            </div>
            {/* grid row 2 */}
            <div className="col-span-2 lg:col-span-1 flex justify-center items-center lg:items-start">
              {/* Left side */}
              <Reveal2>
                <div className="flex col-span-2 lg:col-span-1 justify-center mx-auto w-[300px] lg:w-full">
                  <img src={props.skillsImage} alt="skills img" />
                </div>
              </Reveal2>
            </div>
            {/* Right side */}
            <div className="col-span-2 lg:col-span-1 grid grid-cols-1 items-center lg:items-start">
              <Reveal>
                <div>
                  <h2 className="text-3xl sm:text-4xl lg:text-5xl text-[#def2f1] text-center my-4 lg:my-10">
                    {props.skillsHeading}
                  </h2>
                  {/* skills */}
                  <div className="grid grid-cols-4 lg:grid-cols-3 xl:grid-cols-8">
                    <div className="flex flex-col col-span-1 items-center">
                      {skill1 ? (
                        <p className="text-xl text-[#3aafa9] my-1 rounded-sm px-2">
                          0
                        </p>
                      ) : (
                        <p className="text-xl text-[#feffff] bg-[#17252a] my-1 rounded-sm px-2">
                          {props.skillName1}
                        </p>
                      )}
                      <img
                        src={props.techImage1}
                        alt=""
                        className="w-12 sm:w-16 hover:scale-110 ease-out duration-300"
                        onMouseOver={handleSkill1}
                        onMouseLeave={handlleAllSkills}
                      />
                    </div>
                    <div className="flex flex-col col-span-1 items-center">
                      {skill2 ? (
                        <p className="text-xl text-[#3aafa9] my-1 rounded-sm px-2">
                          0
                        </p>
                      ) : (
                        <p className="text-xl text-[#feffff] bg-[#17252a] my-1 rounded-sm px-2">
                          {props.skillName2}
                        </p>
                      )}
                      <img
                        src={props.techImage2}
                        alt=""
                        className="w-12 sm:w-16 hover:scale-110 ease-out duration-300"
                        onMouseOver={handleSkill2}
                        onMouseLeave={handlleAllSkills}
                      />
                    </div>
                    <div className="flex flex-col col-span-1 items-center">
                      {skill3 ? (
                        <p className="text-xl text-[#3aafa9] my-1 rounded-sm px-2">
                          0
                        </p>
                      ) : (
                        <p className="text-xl text-[#feffff] bg-[#17252a] my-1 rounded-sm px-2">
                          {props.skillName3}
                        </p>
                      )}
                      <img
                        src={props.techImage3}
                        alt=""
                        className="w-12 sm:w-16 hover:scale-110 ease-out duration-300"
                        onMouseOver={handleSkill3}
                        onMouseLeave={handlleAllSkills}
                      />
                    </div>
                    <div className="flex flex-col col-span-1 items-center">
                      {skill4 ? (
                        <p className="text-xl text-[#3aafa9] my-1 rounded-sm px-2">
                          0
                        </p>
                      ) : (
                        <p className="text-xl text-[#feffff] bg-[#17252a] my-1 rounded-sm px-2">
                          {props.skillName4}
                        </p>
                      )}
                      <img
                        src={props.techImage4}
                        alt=""
                        className="w-12 sm:w-16 hover:scale-110 ease-out duration-300"
                        onMouseOver={handleSkill4}
                        onMouseLeave={handlleAllSkills}
                      />
                    </div>
                    <div className="flex flex-col col-span-1 items-center">
                      {skill5 ? (
                        <p className="text-xl text-[#3aafa9] my-1 rounded-sm px-2">
                          0
                        </p>
                      ) : (
                        <p className="text-xl text-[#feffff] bg-[#17252a] my-1 rounded-sm px-2">
                          {props.skillName5}
                        </p>
                      )}
                      <img
                        src={props.techImage5}
                        alt=""
                        className="w-12 sm:w-16 hover:scale-110 ease-out duration-300"
                        onMouseOver={handleSkill5}
                        onMouseLeave={handlleAllSkills}
                      />
                    </div>
                    <div className="flex flex-col col-span-1 items-center">
                      {skill6 ? (
                        <p className="text-xl text-[#3aafa9] my-1 rounded-sm px-2">
                          0
                        </p>
                      ) : (
                        <p className="text-xl text-[#feffff] bg-[#17252a] my-1 rounded-sm px-2">
                          {props.skillName6}
                        </p>
                      )}
                      <img
                        src={props.techImage6}
                        alt=""
                        className="w-12 sm:w-16 hover:scale-110 ease-out duration-300"
                        onMouseOver={handleSkill6}
                        onMouseLeave={handlleAllSkills}
                      />
                    </div>
                    <div className="flex flex-col col-span-1 items-center">
                      {skill7 ? (
                        <p className="text-xl text-[#3aafa9] my-1 rounded-sm px-2">
                          0
                        </p>
                      ) : (
                        <p className="text-xl text-[#feffff] bg-[#17252a] my-1 rounded-sm px-2">
                          {props.skillName7}
                        </p>
                      )}
                      <img
                        src={props.techImage7}
                        alt=""
                        className="w-12 sm:w-16 hover:scale-110 ease-out duration-300"
                        onMouseOver={handleSkill7}
                        onMouseLeave={handlleAllSkills}
                      />
                    </div>
                    <div className="flex flex-col col-span-1 items-center">
                      {skill8 ? (
                        <p className="text-xl text-[#3aafa9] my-1 rounded-sm px-2">
                          0
                        </p>
                      ) : (
                        <p className="text-xl text-[#feffff] bg-[#17252a] my-1 rounded-sm px-2">
                          {props.skillName8}
                        </p>
                      )}
                      <img
                        src={props.techImage8}
                        alt=""
                        className="w-12 sm:w-16 hover:scale-110 ease-out duration-300"
                        onMouseOver={handleSkill8}
                        onMouseLeave={handlleAllSkills}
                      />
                    </div>
                  </div>
                  {/* list of skills */}
                  <div>
                    <ul className="my-8 sm:my-16">
                      <li className="flex items-start lg:items-center">
                        <img
                          src={bullet}
                          alt="/"
                          className="w-7 lg:w-10 rotate-90 mx-4"
                        />
                        <p className="w-full my-0 lg:my-4">
                          {props.desc1}
                        </p>
                      </li>
                      <li className="flex items-start lg:items-center">
                        <img
                          src={bullet}
                          alt="/"
                          className="w-7 lg:w-10 rotate-90 mx-4"
                        />
                        <p className="w-full my-0 lg:my-4">
                          {props.desc2}
                        </p>
                      </li>
                      <li className="flex items-start lg:items-center">
                        <img
                          src={bullet}
                          alt="/"
                          className="w-7 lg:w-10 rotate-90 mx-4"
                        />
                        <p className="w-full my-0 lg:my-4">
                          {props.desc3}
                        </p>
                      </li>
                      <li className="flex items-start lg:items-center">
                        <img
                          src={bullet}
                          alt="/"
                          className="w-7 lg:w-10 rotate-90 mx-4"
                        />
                        <p className="w-full my-0 lg:my-4">
                          {props.desc4}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </Reveal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
