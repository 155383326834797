import React, { useState } from "react";
import edheroImg from "../../assets/education/edhero2.png";
import udemy from "../../assets/education/udemy2.png";
import hackerrank from "../../assets/education/hackerrank2.png";
import duolingo from "../../assets/education/duolingo2.png";
import coursera from "../../assets/education/coursera.png";
import linkedin from "../../assets/education/linkedin.png";
import AnimatedPage from "../Animations/AnimatedPage";

import ju from "../../assets/education/ju.png";
import aust from "../../assets/education/aust.png";
import book from "../../assets/book.png";
import Reveal3 from "../Animations/Reveal3";

const EdHero = () => {
  const [course1, setCourse1] = useState(true);
  const [course2, setCourse2] = useState(true);
  const [course3, setCourse3] = useState(true);
  const [course4, setCourse4] = useState(true);
  const [course5, setCourse5] = useState(true);
  const handleCourse1 = () => {
    setCourse1(!course1);
  };
  const handleCourse2 = () => {
    setCourse2(!course2);
  };
  const handleCourse3 = () => {
    setCourse3(!course3);
  };
  const handleCourse4 = () => {
    setCourse4(!course4);
  };
  const handleCourse5 = () => {
    setCourse5(!course5);
  };

  const handleAllCourses = () => {
    setCourse1(true);
    setCourse2(true);
    setCourse3(true);
    setCourse4(true);
    setCourse5(true);
  };

  return (
    <>
      <AnimatedPage>
        {/* parent div */}
        <div className="w-full h-screen">
          {/* container div */}
          <div className="max-w-[1730px] w-full h-full mx-auto">
            {/* hero */}
            <div className="grid grid-cols-1 lg:grid-cols-2 items-center my-20">
              <div className="col-span-1 m-auto">
                <img src={edheroImg} alt="" />
              </div>
              <div className="col-span-1">
                <h1 className="text-center font-semibold my-16 text-4xl sm:text-5xl lg:text-6xl text-[#def2f1]">
                  Education
                </h1>
                <p className="text-center my-4 text-3xl text-[#17252a] tracking-widest px-4">
                  Basic Qualification and Certifications
                </p>
                {/* Licences and Courses */}
                <div className="grid grid-cols-2 sm:grid-cols-3 2xl:grid-cols-5">
                  {/* udemy */}
                  <div className="flex flex-col col-span-1 items-center">
                    {course1 ? (
                      <p className="text-xl text-[#3aafa9] my-1 rounded-sm px-2">
                        0
                      </p>
                    ) : (
                      <p className="text-xl text-[#feffff] bg-[#17252a] my-1 rounded-sm px-2">
                        Ultimate AWS
                      </p>
                    )}
                    <a
                      href="https://www.udemy.com/certificate/UC-7e47b08d-75ed-45cc-832d-0cd38f0c2c59/"
                      target="target_blank"
                    >
                      <img
                        src={udemy}
                        alt=""
                        className="w-12 sm:w-16 hover:scale-110 ease-out duration-300"
                        onMouseOver={handleCourse1}
                        onMouseLeave={handleAllCourses}
                      />
                    </a>
                  </div>
                  <div className="flex flex-col col-span-1 items-center">
                    {course2 ? (
                      <p className="text-xl text-[#3aafa9] my-1 rounded-sm px-2">
                        0
                      </p>
                    ) : (
                      <p className="text-xl text-[#feffff] bg-[#17252a] my-1 rounded-sm px-2">
                        Data Proccessing
                      </p>
                    )}
                    <a
                      href="https://www.coursera.org/account/accomplishments/certificate/NGHDRWMA4KUW"
                      target="target_blank"
                    >
                      <img
                        src={coursera}
                        alt=""
                        className="w-12 sm:w-16 hover:scale-110 ease-out duration-300"
                        onMouseOver={handleCourse2}
                        onMouseLeave={handleAllCourses}
                      />
                    </a>
                  </div>
                  <div className="flex flex-col col-span-1 items-center">
                    {course3 ? (
                      <p className="text-xl text-[#3aafa9] my-1 rounded-sm px-2">
                        0
                      </p>
                    ) : (
                      <p className="text-xl text-[#feffff] bg-[#17252a] my-1 rounded-sm px-2">
                        English Testing
                      </p>
                    )}
                    <a
                      href="https://certs.duolingo.com/b81d9753b0c65bc990ebc8ea49029e89"
                      target="target_blank"
                    >
                      <img
                        src={duolingo}
                        alt=""
                        className="w-12 sm:w-16 hover:scale-110 ease-out duration-300"
                        onMouseOver={handleCourse3}
                        onMouseLeave={handleAllCourses}
                      />
                    </a>
                  </div>
                  <div className="flex flex-col col-span-1 items-center">
                    {course4 ? (
                      <p className="text-xl text-[#3aafa9] my-1 rounded-sm px-2">
                        0
                      </p>
                    ) : (
                      <p className="flex text-xl text-[#feffff] bg-[#17252a] my-1 rounded-sm px-2">
                        Python Basic
                      </p>
                    )}
                    <a
                      href="https://www.hackerrank.com/certificates/a7d0d3d0f771"
                      target="target_blank"
                    >
                      <img
                        src={hackerrank}
                        alt=""
                        className="w-12 sm:w-16 hover:scale-110 ease-out duration-300"
                        onMouseOver={handleCourse4}
                        onMouseLeave={handleAllCourses}
                      />
                    </a>
                  </div>
                  <div className="flex flex-col col-span-1 items-center">
                    {course5 ? (
                      <p className="text-xl text-[#3aafa9] my-1 rounded-sm px-2">
                        0
                      </p>
                    ) : (
                      <p className="text-xl text-[#feffff] bg-[#17252a] my-1 rounded-sm px-2">
                        Go Essential
                      </p>
                    )}
                    <a
                      href="https://www.linkedin.com/learning/certificates/c9f423d4301fac417b6fe13fae1887c02f1c5d664a593b8161d6f75274e877c4?trk=backfilled_certificate&lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3Btk4HLM02TQSZD8yi%2FjQ74w%3D%3D"
                      target="target_blank"
                    >
                      <img
                        src={linkedin}
                        alt=""
                        className="w-12 sm:w-16 hover:scale-110 ease-out duration-300"
                        onMouseOver={handleCourse5}
                        onMouseLeave={handleAllCourses}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* education section */}
            <div>
              <div className="py-16">
                <h1 className="text-center text-5xl font-semibold">
                  Degrees Achieved
                </h1>
              </div>

              {/* grid 1 */}
              <Reveal3>
                <div className="grid grid-cols-8 my-16">
                  {/* left side */}
                  {/* image container */}
                  <div className="my-8 lg:my-4 p-4 rounded-full shadow-lg shadow-[#2b7a78] col-span-8 xl:col-span-2 w-[280px] mx-auto hover:scale-105 duration-300 ease-out">
                    <div className="rounded-full bg-[#feffff] w-full h-full flex">
                      <img src={ju} alt="" />
                    </div>
                  </div>
                  {/* right side row 1*/}
                  <div className="col-span-8 xl:col-span-6 shadow-lg rounded-xl mx-6">
                    <div className="grid grid-cols-6 bg-[#17252a] px-4 rounded-xl rounded-b-none">
                      {/* inner left */}
                      <div className="grid col-span-5 ">
                        <p className="text-2xl text-[#def2f1] my-4">
                          Jahangirnagar University
                        </p>
                        <p className="text-lg text-[#3aafa9] mb-2">
                          Post Graduate Degree, Information Technology
                        </p>
                      </div>
                      {/* inner right */}
                      <div className="grid col-span-1 text-center items-center">
                        <p className="text-[#3aafa9] text-xl">April 2023</p>
                      </div>
                    </div>
                    {/* right side row 2 */}
                    <div className="px-4">
                      <ul>
                        <li className="my-2 flex items-center">
                          <img src={book} alt="" className="w-6 mx-2 h-full" />I
                          learned advanced knowledge of computer science
                          fundamentals, such as data structures, algorithms, and
                          operating systems
                        </li>
                        <li className="my-2 flex items-center">
                          <img src={book} alt="" className="w-6 mx-2 h-full" />I
                          also gained an in-depth understanding of specific IT
                          topics, such as cloud computing, big data, and
                          artificial intelligence
                        </li>
                        <li className="my-2 flex items-center">
                          <img src={book} alt="" className="w-6 mx-2 h-full" />I
                          developed strong analytical and problem-solving
                          skills. I learned how to design and implement IT
                          solutions
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Reveal3>

              {/* grid 2 */}
              <Reveal3>
                <div className="grid grid-cols-8 my-16">
                  {/* left side */}
                  {/* image container */}
                  <div className="my-8 lg:my-4 p-4 rounded-lg shadow-lg shadow-[#2b7a78] col-span-8 xl:col-span-2 mx-auto hover:scale-105 duration-300 ease-out">
                    <div className="w-full h-full flex">
                      <img src={aust} alt="" />
                    </div>
                  </div>
                  {/* right side row 1*/}
                  <div className="col-span-8 xl:col-span-6 shadow-lg rounded-xl mx-6">
                    <div className="grid grid-cols-6 bg-[#17252a] px-4 rounded-xl rounded-b-none">
                      {/* inner left */}
                      <div className="grid col-span-5 ">
                        <p className="text-2xl text-[#def2f1] my-4">
                          Ahsanullah University Of Science & Technology
                        </p>
                        <p className="text-lg text-[#3aafa9] mb-2">
                          MBA, Accounting
                        </p>
                      </div>
                      {/* inner right */}
                      <div className="grid col-span-1 text-center items-center">
                        <p className="text-[#3aafa9] text-xl">2014-2016</p>
                      </div>
                    </div>
                    {/* right side row 2 */}
                    <div className="px-4">
                      <ul className="my-4">
                        <li className="my-2 flex">
                          <img src={book} alt="" className="w-6 mx-2 h-full" />I
                          learned how to read and interpret financial
                          statements, help businesses and individuals plan their
                          taxes
                        </li>
                        <li className="my-2 flex">
                          <img src={book} alt="" className="w-6 mx-2 h-full" />I
                          also gained knowledge about helping managers about the
                          different types of risks that businesses face and how
                          to manage them, learn how to use data to make informed
                          decisions, and learn about the ethical principles that
                          should guide the conduct of accountants
                        </li>
                        <li className="my-2 flex">
                          <img src={book} alt="" className="w-6 mx-2 h-full" />I
                          developed my critical thinking, analytical., and
                          problem-solving skills.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Reveal3>

              {/* grid 3 */}
              <Reveal3>
                <div className="grid grid-cols-8 my-16 pb-5 lg:pb-10">
                  {/* left side */}
                  {/* image container */}
                  <div className="my-8 lg:my-4 p-4 rounded-lg shadow-lg shadow-[#2b7a78] col-span-8 xl:col-span-2 mx-auto hover:scale-105 duration-300 ease-out">
                    <div className="w-full h-full flex">
                      <img src={aust} alt="" />
                    </div>
                  </div>
                  {/* right side row 1*/}
                  <div className="col-span-8 xl:col-span-6 shadow-lg rounded-xl mx-6">
                    <div className="grid grid-cols-6 bg-[#17252a] px-4 rounded-xl rounded-b-none">
                      {/* inner left */}
                      <div className="grid col-span-5 ">
                        <p className="text-2xl text-[#def2f1] my-4">
                          Ahsanullah University Of Science & Technology
                        </p>
                        <p className="text-lg text-[#3aafa9] mb-2">
                          BBA, Business Administration
                        </p>
                      </div>
                      {/* inner right */}
                      <div className="grid col-span-1 text-center items-center">
                        <p className="text-[#3aafa9] text-xl">2009-2013</p>
                      </div>
                    </div>
                    {/* right side row 2 */}
                    <div className="px-4">
                      <ul className="my-4">
                        <li className="my-2 flex ">
                          <img src={book} alt="" className="w-6 mx-2 h-full" />
                          Record financial transactions, prepare financial
                          statements, and analyze financial data.Legal
                          principles that govern business, such as contract law,
                          property law, and employment law
                        </li>
                        <li className="my-2 flex ">
                          <img src={book} alt="" className="w-6 mx-2 h-full" />
                          Learn about the economic concepts that shape the
                          business world, such as supply and demand, market
                          competition, and economic growth
                        </li>
                        <li className="my-2 flex ">
                          <img src={book} alt="" className="w-6 mx-2 h-full" />
                          Principles of finance, such as how to raise capital,
                          manage cash flow, and make investment decisions. Learn
                          about the strategies and techniques used in marketing,
                          such as product development, pricing, and promotion
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Reveal3>
            </div>
          </div>
        </div>
      </AnimatedPage>
    </>
  );
};

export default EdHero;
