import React, { useEffect, useState } from "react";
import navLogo from "../assets/logo.png";
import { CiMenuFries } from "react-icons/ci";
import { TfiClose } from "react-icons/tfi";
import Top from "./Top";

const Navbar = () => {
  const [nav, setNavbar] = useState(false);
  const handleNav = () => setNavbar(!nav);

  const [btn, setBtn] = useState(false);

  useEffect(() => {
    const handleBtn = () => {
      if (window.scrollY >= 90) {
        setBtn(true);
      } else {
        setBtn(false);
      }
    };
    window.addEventListener("scroll", handleBtn);
  }, []);

  return (
    // parent div
    <nav name="navbar" className="w-full h-28 z-100 pl-8 lg:pl-0">
      {/* container div */}
      <div className="flex max-w-[1730px] mx-auto justify-between items-center w-full h-full px-2 2xl:px-4 z-50">
        <img src={navLogo} alt="nav logo" className="w-[150px] lg:w-[200px]" />
        <div>
          <ul className="hidden lg:flex">
            <a href="/">
              <li className="text-xl hover:bg-[#17252a] py-4 px-5 hover:text-[#def2f1] cursor-pointer rounded-sm font-bold">
                Home
              </li>
            </a>
            <a href="/education">
              <li className="text-xl hover:bg-[#17252a] py-4 px-5 hover:text-[#def2f1] cursor-pointer rounded-sm font-bold">
                Education
              </li>
            </a>
            <a href="/experience">
              <li className="text-xl hover:bg-[#17252a] py-4 px-5 hover:text-[#def2f1] cursor-pointer rounded-sm font-bold">
                Experience
              </li>
            </a>
            <a href="/projects">
              <li className="text-xl hover:bg-[#17252a] py-4 px-5 hover:text-[#def2f1] cursor-pointer rounded-sm font-bold">
                Projects
              </li>
            </a>
            <a href="/open">
              <li className="text-xl hover:bg-[#17252a] py-4 px-5 hover:text-[#def2f1] cursor-pointer rounded-sm font-bold">
                Open Source
              </li>
            </a>
            <a href="/contacts">
              <li className="text-xl hover:bg-[#17252a] py-4 px-5 hover:text-[#def2f1] cursor-pointer rounded-sm font-bold">
                Contact Me
              </li>
            </a>
          </ul>

          {/* mobile menu icon */}
          <div
            className="lg:hidden cursor-pointer hover:bg-[#17252a] hover:text-[#def2f1] p-2 rounded-sm"
            onClick={handleNav}
          >
            <CiMenuFries size={30} />
          </div>
        </div>
      </div>

      {/* Mobile menu container */}
      <div
        className={
          nav
            ? "fixed left-0 top-0 w-full h-screen bg-black/70 lg:hidden z-10"
            : ""
        }
      >
        {/* mobile menu */}
        <div
          className={
            nav
              ? "fixed left-0 top-0 w-[75%] sm:w-[60%] lg:w-[45%] h-screen bg-[#3aafa9] p-10 ease-in duration-300"
              : "fixed left-[-100%] top-0 w-[75%] sm:w-[60%] lg:w-[45%] h-screen bg-[#3aafa9] p-10 ease-in duration-300"
          }
        >
          {/* Upperpart */}
          <div className="flex w-full justify-between items-center">
            <img
              src={navLogo}
              alt="nav logo"
              className="w-[120px] md:w-[150px]"
            />
            <div
              onClick={handleNav}
              className="cursor-pointer hover:bg-[#17252a] hover:text-[#def2f1] p-2 rounded-sm"
            >
              <TfiClose size={30} />
            </div>
          </div>
          {/* lower part */}
          <div className="py-4 flex flex-col">
            <ul className="">
              <a href="/">
                <li className="py-4 text-xl hover:bg-[#17252a] hover:text-[#def2f1] cursor-pointer rounded- px-4 font-bold">
                  Home
                </li>
              </a>
              <a href="/education">
                <li className="py-4 text-xl hover:bg-[#17252a] hover:text-[#def2f1] cursor-pointer rounded- px-4 font-bold">
                  Education
                </li>
              </a>
              <a href="/experience">
                <li className="py-4 text-xl hover:bg-[#17252a] hover:text-[#def2f1] cursor-pointer rounded- px-4 font-bold">
                  Experience
                </li>
              </a>
              <a href="/projects">
                <li className="py-4 text-xl hover:bg-[#17252a] hover:text-[#def2f1] cursor-pointer rounded- px-4 font-bold">
                  Projects
                </li>
              </a>
              <a href="/open">
                <li className="py-4 text-xl hover:bg-[#17252a] hover:text-[#def2f1] cursor-pointer rounded- px-4 font-bold">
                  Open Source
                </li>
              </a>
              <a href="/contacts">
                <li className="py-4 text-xl hover:bg-[#17252a] hover:text-[#def2f1] cursor-pointer rounded- px-4 font-bold">
                  Contact Me
                </li>
              </a>
            </ul>
          </div>
        </div>
      </div>
      {btn ? <Top /> : ""}
    </nav>
  );
};

export default Navbar;
