import python from "../assets/skills/python2.png";
import django from "../assets/skills/django2.png";
import react from "../assets/skills/react.png";
import express from "../assets/skills/express.png";
import mongo from "../assets/skills/mongo.png";
import aws from "../assets/skills/aws.png";
import docker from "../assets/skills/docker.png";
import postgre from "../assets/skills/postgre.png";
import node from "../assets/skills/node-js.png";
import golang from "../assets/skills/golang.png";
import sql from "../assets/skills/sql.png";
import api from "../assets/skills/api.png";
import js from "../assets/skills/js.png";
import html from "../assets/skills/html.png";
import css from "../assets/skills/css.png";
import azure from "../assets/skills/azure.png";
import git from "../assets/skills/git.png";

// Add projects below like the following key-value pairs

export const data = [

  {
    id: 1,
    name: "ERP",
    text: "Developing a Financial transaction and accounting module for an ERP for a Canadian Client",
    role: "Team Lead",
    image1: python,
    image2: django,
    link: "#",
    date: "Dec 2021 - May 2024. Ixora Solution Ltd.",
  },
  {
    id: 2,
    name: "Car Rental Management",
    text: "Developing a car rental marketplace for Australian Client",
    role: "Backend Team Lead",
    image1: python,
    image2: django,
    image3: react,
    image4: express,
    image5: mongo,
    image6: aws,
    image7: docker,
    link: "https://thejaunt.com.au/",
    date: "Dec 2021 - May 2024. Ixora Solution Ltd.",
  },
  {
    id: 3,
    name: "Chemical Management",
    text: "with AI Developing the back-end part of an AI-based management system",
    role: "Sr. Software Developer",
    image1: python,
    image2: django,
    image3: postgre,
    image4: docker,
    link: "#",
    date: "Dec 2021 - May 2024. Ixora Solution Ltd.",
  },
  {
    id: 4,
    name: "Build a SaaS for Digital CX",
    text: "Designed and architected a chatbot SaaS-based system. Manage multi-channel conversations from one place with AI Assist",
    role: "Senior Software Engineer",
    image1: python,
    image2: django,
    image3: node,
    image4: express,
    image5: react,
    image6: postgre,
    image7: docker,
    image8: mongo,
    date: "June 2021 - Nov 2021. Genex Digital",
    link: "https://www.mevrik.com/",
  },
  {
    id: 5,
    name: "Adfinix",
    text: "A full-stack ad tech platform that uses AI to empower date on digital advertising. AI-Powered Digital Media Buying Solution",
    role: "Team Lead",
    image1: python,
    image2: django,
    image3: node,
    image4: express,
    image5: react,
    image6: golang,
    image7: aws,
    image8: postgre,
    image9: api,
    link: "https://adfinix.com/",
    date: "Dec 2019 – May 2021. Adfinix Ltd.",
  },
  {
    id: 6,
    name: "Jobike",
    text: "An on-demand bike-sharing service that allows everyone to make short trips easily within the coverage area(s).",
    role: "Software Engineer",
    image1: python,
    image2: django,
    image4: express,
    image5: api,
    image6: sql,
    image7: golang,
    image8: postgre,
    image9: aws,
    link: "https://www.jo.bike/",
    date: "Jan 2019 – Nov 2019. Jobike",
  },
  {
    id: 7,
    name: "Shuni Tel",
    text: "Bangladesh’s first commercial-grade AI product developed for our telecom industry. Shuni is a Natural Language Processing (NLP) application that uses Deep Learning to monitor & analyze social media campaigns",
    role: "Team Lead",
    image1: python,
    image2: django,
    image3: node,
    image5: react,
    image4: js,
    image6: html,
    image7: css,
    image8: sql,
    image9: azure,
    image10: git,
    image11: aws,
    link: "#",
    date: "Aug 2017 – Jan 2019. DataShall Analytics Ltd",
  },
];
