import React from "react";
import { IoIosLink, IoIosStar, IoLogoGithub } from "react-icons/io";
import heroImg from "../../assets/virtual-reality.png";
import { FaGoogle, FaLinkedinIn, FaXTwitter } from "react-icons/fa6";

const Hero = () => {
  return (
    <>
    {/* Parent div */}
    <div className="w-full h-screen my-40 sm:my-0">
      {/* container div */}
      <div className="max-w-[1730px] w-full h-full mx-auto p-2 flex justify-center items-center">
        {/* grid div */}
        <div className="grid grid-cols-2 p-8 sm:p-16 mb-24">
          {/* left side */}
          <div className="col-span-2 sm:col-span-1 text-center sm:text-left">
            <h1 className="text-4xl sm:text-7xl lg:text-8xl font-bold text-[#feffff] my-4 md:my-12">
              Shariful Islam
            </h1>
            <p className="text-xl sm:text-2xl lg:text-3xl my-4 text-[#1f5a58]">
              I am a Software Developer with over 9 years of experience in
              building and maintaining microservices. Have a proven track record
              of success in developing scalable and reliable systems that can
              handle millions of requests.
            </p>
            {/* Social icons div */}
            <div className="flex justify-center sm:justify-start items-center py-8">
              {/* github */}
              <div className="pr-2">
                <a href="https://github.com/nayonacademy" target="target_blank">
                  <div className="p-2 rounded-full text-white bg-black border-none hover:bg-[#2b7a78] ease-in-out duration-300">
                    <IoLogoGithub size={33} />
                  </div>
                </a>
              </div>
              {/* linkedin */}
              <div className="px-2">
                <a
                  href="https://www.linkedin.com/in/shariful-islam-tech/"
                  target="target_blank"
                >
                  <div className="p-2 rounded-full text-white bg-[#0077B5] border-none hover:bg-[#2b7a78] ease-in-out duration-300">
                    <FaLinkedinIn size={33} />
                  </div>
                </a>
              </div>
              {/* x */}
              <div className="px-2">
                <a
                  href="https://twitter.com/nayonacademy"
                  target="target_blank"
                >
                  <div className="p-2 rounded-full text-white bg-black border-none hover:bg-[#2b7a78] ease-in-out duration-300">
                    <FaXTwitter size={33} />
                  </div>
                </a>
              </div>
              {/* gmail */}
              <div className="px-2">
                <a href="mailto:si.nayon@gmail.com" target="target_blank">
                  <div className="p-2 rounded-full text-white bg-[#ea4435] border-none hover:bg-[#2b7a78] ease-in-out duration-300">
                    <FaGoogle size={33} />
                  </div>
                </a>
              </div>
              {/* nayon site */}
              <div className="pl-2">
                <a href="https://nayon.net" target="target_blank">
                  <div className="p-2 rounded-full text-white bg-[#67de50] border-none hover:bg-[#2b7a78] ease-in-out duration-300">
                    <IoIosLink size={33} />
                  </div>
                </a>
              </div>
            </div>
            {/* Button */}
            <div className="my-4">
              <button className="border-2 duration-300 bg-[#17252a] text-[#feffff] py-2 px-4 rounded-md hover:bg-[#3aafa9] hover:border-2 border-[#17252a] hover:text-[#17252a]">
                <div className="flex items-center">
                  <IoIosStar size={20} className="text-yellow-400" />
                  <span className="text-xl px-2 tracking-widest">
                    Star Me On Github
                  </span>
                </div>
              </button>
            </div>
          </div>
          {/* right side */}
          <div className="grid col-span-2 sm:col-span-1 justify-center items-center my-8 w-[300px] sm:w-full mx-auto">
            <img src={heroImg} alt="hero img" />
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Hero;
