import React from "react";
import { data2 } from "../../data/Data2";
import Reveal3 from "../Animations/Reveal3";
import { IoLogoGithub } from "react-icons/io";
import Reveal2 from "../Animations/Reveal2";
import { MdArrowOutward } from "react-icons/md";

function Repos() {
  return (
    <>
      <Reveal3>
        <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 gap-8 p-8">
          {data2.map((item, index) => (
            <div
              key={index}
              className="bg-[#2b7a78] shadow-lg hover:scale-105 ease-out duration-300 p-4 rounded-lg shadow-[#17252a]"
            >
              <Reveal2>
                <h1 className="flex items-center text-2xl lg:text-3xl text-[#def2f1] font-bold my-4">
                  <span className="mr-2">
                    <IoLogoGithub className="text-[#17252a]" />
                  </span>
                  {item.name}
                </h1>
                <p className="italic text-emerald-400 text-sm my-2">
                  {item.date}
                </p>
                <p className="text-md lg:text-xl my-8">{item.text}</p>
                {/* Tech used */}
                <div className="my-8 flex flex-wrap">
                  {item.image1 && (
                    <img
                      src={item.image1}
                      alt={item.name}
                      className="w-[30px] lg:w-[40px] m-2"
                    />
                  )}
                  {item.image2 && (
                    <img
                      src={item.image2}
                      alt={item.name}
                      className="w-[30px] lg:w-[40px] m-2"
                    />
                  )}
                  {item.image3 && (
                    <img
                      src={item.image3}
                      alt={item.name}
                      className="w-[30px] lg:w-[40px] m-2"
                    />
                  )}
                  {item.image4 && (
                    <img
                      src={item.image4}
                      alt={item.name}
                      className="w-[30px] lg:w-[40px] m-2"
                    />
                  )}
                  {item.image5 && (
                    <img
                      src={item.image5}
                      alt={item.name}
                      className="w-[30px] lg:w-[40px] m-2"
                    />
                  )}
                  {item.image6 && (
                    <img
                      src={item.image6}
                      alt={item.name}
                      className="w-[30px] lg:w-[40px] m-2"
                    />
                  )}
                  {item.image7 && (
                    <img
                      src={item.image7}
                      alt={item.name}
                      className="w-[30px] lg:w-[40px] m-2"
                    />
                  )}
                  {item.image8 && (
                    <img
                      src={item.image8}
                      alt={item.name}
                      className="w-[30px] lg:w-[40px] m-2"
                    />
                  )}
                  {item.image9 && (
                    <img
                      src={item.image9}
                      alt={item.name}
                      className="w-[30px] lg:w-[40px] m-2"
                    />
                  )}
                  {item.image10 && (
                    <img
                      src={item.image10}
                      alt={item.name}
                      className="w-[30px] lg:w-[40px] m-2"
                    />
                  )}
                  {item.image11 && (
                    <img
                      src={item.image11}
                      alt={item.name}
                      className="w-[30px] lg:w-[40px] m-2"
                    />
                  )}
                </div>
                <div className="flex items-center justify-center hover:text-[#3aafa9] text-[#17252a] cursor-pointer">
                  <a
                    href={item.link}
                    className="text-md lg:text-lg mr-1"
                    target="target_blank"
                  >
                    Link to repository
                  </a>
                  <MdArrowOutward size={24} />
                </div>
              </Reveal2>
            </div>
          ))}
        </div>
      </Reveal3>
    </>
  );
}

export default Repos;
