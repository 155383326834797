// <-- Usable tech images -->

// import django from "../assets/skills/django2.png";
// import react from "../assets/skills/react.png";
// import express from "../assets/skills/express.png";
// import mongo from "../assets/skills/mongo.png";
// import aws from "../assets/skills/aws.png";
// import postgre from "../assets/skills/postgre.png";
// import node from "../assets/skills/node-js.png";
// import sql from "../assets/skills/sql.png";
// import api from "../assets/skills/api.png";
// import js from "../assets/skills/js.png";
// import css from "../assets/skills/css.png";
// import azure from "../assets/skills/azure.png";
// import git from "../assets/skills/git.png";

import python from "../assets/skills/python2.png";
import html from "../assets/skills/html.png";
import golang from "../assets/skills/golang.png";
import docker from "../assets/skills/docker.png";
import csharp from "../assets/projects/c-sharp.png";
import cplus from "../assets/projects/c-.png";
import java from "../assets/projects/java.png";
import makefile from "../assets/projects/makefile.png";

// Add repositories below like the following key-value pairs

export const data2 = [
  {
    id: 1,
    name: "openai-go",
    text: "OpenAI golang library that will support, ChatGPT, GPT-3, GPT-4, DALL·E, Whisper API. This is a golang wrapper library",
    image1: golang,
    link: "https://github.com/nayonacademy/openai-go",
    date: "Created in 2023",
  },
  {
    id: 2,
    name: "goapi",
    text: "Golang api framework",
    image1: golang,
    link: "https://github.com/nayonacademy/goapi",
    date: "Created in 2020",
  },
  {
    id: 3,
    name: "conference-tracker",
    text: "Using go-kit library to build microservice",
    image1: golang,
    link: "https://github.com/nayonacademy/conference-tracker",
    date: "Created in 2020",
  },
  {
    id: 4,
    name: "problem-solving",
    text: "Online judge Hackerrank, timus, URI Online Judge solutions with c/c++, python, golang",
    image1: cplus,
    image2: python,
    image3: java,
    image4: golang,
    image5: csharp,
    link: "https://github.com/nayonacademy/problem-solving",
    date: "Created in 2019",
  },
  {
    id: 5,
    name: "golang-lab",
    text: "Golang Lab",
    image1: golang,
    link: "https://github.com/nayonacademy/golang-lab",
    date: "Created in 2019",
  },
  {
    id: 6,
    name: "django-docker-boilerplate",
    text: "Deploy Django using Nginx, Celery, Redis and Postgresql with Docker",
    image1: python,
    image2: html,
    image3: docker,
    image4: makefile,
    link: "https://github.com/nayonacademy/django-docker-boilerplate",
    date: "Created in 2023",
  },
];
