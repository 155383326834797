import React from "react";
import AnimatedPage from "../Animations/AnimatedPage";

import office from "../../assets/experience/office.png";
import genex from "../../assets/experience/genex.jpg";
import adfinix from "../../assets/experience/adfinix.jpg";
import jobike from "../../assets/experience/jobike.jpg";
import datashell from "../../assets/experience/datashell.jpg";
import Reveal3 from "../Animations/Reveal3";

const ExHero = () => {
  return (
    <>
      <AnimatedPage>
        {/* parent div */}
        <div className="w-full h-screen">
          {/* container div */}
          <div className="max-w-[1730px] w-full h-full mx-auto">
            {/* hero */}
            <div className="grid grid-cols-1 lg:grid-cols-2 items-center my-20">
              <div className="col-span-1 m-auto px-8 sm:px-0">
                <img src={office} alt="" />
              </div>
              <div className="col-span-1">
                <h1 className="text-center font-semibold my-8 text-4xl sm:text-5xl lg:text-6xl text-[#def2f1]">
                  Experience
                </h1>
                <p className="text-center my-8 text-3xl text-[#17252a] tracking-widest">
                  All Full-time Work
                </p>
              </div>
            </div>

            {/* work container */}
            {/* genex */}
            <Reveal3>
              <div className="grid grid-cols-10 px-2 lg:px-8 py-16 gap-4 lg:gap-16">
                {/* left side */}
                <div className="col-span-10 md:col-span-2 lg:col-span-1">
                  <div className="flex justify-center items-center w-full h-full">
                    {/* image container */}
                    <div className="p-2 shadow-[#17252a] shadow-xl rounded-lg hover:scale-105 ease-out duration-300">
                      <img src={genex} alt="" className="rounded-lg" />
                    </div>
                  </div>
                </div>
                {/* right side */}
                <div className="col-span-10 md:col-span-8 lg:col-span-9 rounded-xl p-8 shadow-lg shadow-[#17252a]">
                  <div className="grid grid-cols-9">
                    <div className="col-span-9 lg:col-span-8">
                      <h4 className="text-2xl font-bold my-2">
                        Senior Software Engineer
                      </h4>
                      <p className="text-lg text-[#def2f1]">
                        Genex Digital - Full-time
                      </p>
                      <p className="my-4 text-lg">
                        Designed and architected a chatbot SaaS-based system
                        using Python, Golang, Postgresql, MongoDB, Docker,
                        Go-gin, and Pub-Sub. Managed a microservice system
                        consisting of over 10 microservices. Successfully
                        improved the system's performance by 20% and reduced its
                        downtime by 50%. Led a team of 13+ developers.
                        Successfully delivered multiple projects on time and
                        within budget.
                      </p>
                    </div>
                    <div className="col-span-9 lg:col-span-1">
                      <p className="text-lg text-[#def2f1]">
                        Jun 2021 - Dec 2021
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Reveal3>

            {/* adfinix */}
            <Reveal3>
              <div className="grid grid-cols-10 px-2 lg:px-8 py-16 gap-4 lg:gap-16">
                {/* left side */}
                <div className="col-span-10 md:col-span-2 lg:col-span-1">
                  <div className="flex justify-center items-center w-full h-full">
                    {/* image container */}
                    <div className="p-2 shadow-[#17252a] shadow-xl rounded-lg hover:scale-105 ease-out duration-300">
                      <img src={adfinix} alt="" className="rounded-lg" />
                    </div>
                  </div>
                </div>
                {/* right side */}
                <div className="col-span-10 md:col-span-8 lg:col-span-9 rounded-xl p-8 shadow-lg shadow-[#17252a]">
                  <div className="grid grid-cols-9">
                    <div className="col-span-8">
                      <h4 className="text-2xl font-bold my-2">
                        Senior Software Engineer
                      </h4>
                      <p className="text-lg text-[#def2f1]">
                        Adfinix - Full-time
                      </p>
                      <p className="my-4 text-lg">
                        Worked as a core member of the team that built the SSP
                        platforms: I was responsible for designing, developing,
                        and testing the SSP platforms. This included working
                        closely with other engineers to ensure that the
                        platforms met the requirements of our customers.I built
                        APIs with Python for the SSP platforms. This included
                        ensuring that the APIs were well-documented and easy to
                        use. I contributed to the SSP system in Golang. This
                        included fixing bugs and adding new features.
                      </p>
                    </div>
                    <div className="col-span-9 lg:col-span-1">
                      <p className="text-lg text-[#def2f1]">
                        Jan 2021 - May 2021
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Reveal3>

            {/* genex */}
            <Reveal3>
              <div className="grid grid-cols-10 px-2 lg:px-8 py-16 gap-4 lg:gap-16">
                {/* left side */}
                <div className="col-span-10 md:col-span-2 lg:col-span-1">
                  <div className="flex justify-center items-center w-full h-full">
                    {/* image container */}
                    <div className="p-2 shadow-[#17252a] shadow-xl rounded-lg hover:scale-105 ease-out duration-300">
                      <img src={genex} alt="" className="rounded-lg" />
                    </div>
                  </div>
                </div>
                {/* right side */}
                <div className="col-span-10 md:col-span-8 lg:col-span-9 rounded-xl p-8 shadow-lg shadow-[#17252a]">
                  <div className="grid grid-cols-9">
                    <div className="col-span-8">
                      <h4 className="text-2xl font-bold my-2">
                        Senior Software Engineer
                      </h4>
                      <p className="text-lg text-[#def2f1]">
                        Genex Digital - Full-time
                      </p>
                      <p className="my-4 text-lg">
                        Designed, developed, and maintained the AdTech platform:
                        This included designing the architecture, developing the
                        code, and testing the platform. Developed microservices
                        in Golang and Python: I developed microservices in
                        Golang and Python for the platform. This included
                        breaking down the platform into smaller components that
                        could be independently developed and deployed.
                      </p>
                    </div>
                    <div className="col-span-9 lg:col-span-1">
                      <p className="text-lg text-[#def2f1]">
                        Dec 2019 - Dec 2020
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Reveal3>

            {/* jobike */}
            <Reveal3>
              <div className="grid grid-cols-10 px-2 lg:px-8 py-16 gap-4 lg:gap-16">
                {/* left side */}
                <div className="col-span-10 md:col-span-2 lg:col-span-1">
                  <div className="flex justify-center items-center w-full h-full">
                    {/* image container */}
                    <div className="p-2 shadow-[#17252a] shadow-xl rounded-lg hover:scale-105 ease-out duration-300">
                      <img src={jobike} alt="" className="rounded-lg" />
                    </div>
                  </div>
                </div>
                {/* right side */}
                <div className="col-span-10 md:col-span-8 lg:col-span-9 rounded-xl p-8 shadow-lg shadow-[#17252a]">
                  <div className="grid grid-cols-9">
                    <div className="col-span-8">
                      <h4 className="text-2xl font-bold my-2">
                        Software Engineer
                      </h4>
                      <p className="text-lg text-[#def2f1]">
                        Genex Digital - Full-time
                      </p>
                      <p className="my-4 text-lg">
                        I was responsible for designing, developing, and
                        maintaining the v2 platform using Golang, Python, and
                        Django. I have experience with a variety of
                        technologies, including Python, Django, Golang,
                        Microservices, and System Management APIs. I implemented
                        the payment gateway for the v2 platform. This included
                        integrating with the payment processor and ensuring that
                        the gateway was secure and reliable.
                      </p>
                    </div>
                    <div className="col-span-9 lg:col-span-1">
                      <p className="text-lg text-[#def2f1]">
                        Jun 2021 - Dec 2021
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Reveal3>

            {/* datashell */}
            <Reveal3>
              <div className="grid grid-cols-10 px-2 lg:px-8 py-16 gap-4 lg:gap-16">
                {/* left side */}
                <div className="col-span-10 md:col-span-2 lg:col-span-1">
                  <div className="flex justify-center items-center w-full h-full">
                    {/* image container */}
                    <div className="p-2 shadow-[#17252a] shadow-xl rounded-lg hover:scale-105 ease-out duration-300">
                      <img src={datashell} alt="" className="rounded-lg" />
                    </div>
                  </div>
                </div>
                {/* right side */}
                <div className="col-span-10 md:col-span-8 lg:col-span-9 rounded-xl p-8 shadow-lg shadow-[#17252a]">
                  <div className="grid grid-cols-9">
                    <div className="col-span-8">
                      <h4 className="text-2xl font-bold my-2">
                        Full Stack Python Programmer
                      </h4>
                      <p className="text-lg text-[#def2f1]">
                        Genex Digital - Full-time
                      </p>
                      <p className="my-4 text-lg">
                        Full-stack web app development using Python, Django,
                        JavaScript, HTML, CSS, and SQL. Proficient use of Git
                        and GitHub for code management. Docker for creating
                        scalable applications. Hosted and scaled apps on AWS and
                        DigitalOcean. Efficient troubleshooting and issue
                        resolution. Continuous learning to stay updated. UI
                        design with d3js for charts. Team leadership and
                        collaboration. Embracing new challenges and learning
                        opportunities.
                      </p>
                    </div>
                    <div className="col-span-9 lg:col-span-1">
                      <p className="text-lg text-[#def2f1]">
                        Jun 2021 - Dec 2021
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Reveal3>
          </div>
        </div>
      </AnimatedPage>
    </>
  );
};
export default ExHero;
