import React from "react";
import Hero from "../../components/Home/Hero";
import Skills from "../../components/Home/Skills";

import skillsImg1 from "../../assets/skillsImg1.png";
import skillsImg2 from "../../assets/skillsImg2.png";
import skillsImg3 from "../../assets/skillsImg3.png";
import skillsImg4 from "../../assets/skillsImg4.png";

import python from "../../assets/skills/python2.png";
import js from "../../assets/skills/js.png";
import dj from "../../assets/skills/django2.png";
import golang from "../../assets/skills/golang.png";
import kafka from "../../assets/skills/kafka.png";
import rabbit from "../../assets/skills/rabbitmq.png";
import sql from "../../assets/skills/sql.png";
import api from "../../assets/skills/api.png";

import aws from "../../assets/skills/aws.png";
import postgre from "../../assets/skills/postgre.png";
import mongo from "../../assets/skills/mongo.png";
import docker from "../../assets/skills/docker.png";
import git from "../../assets/skills/git.png";
import gitlab from "../../assets/skills/gitlab.png";
import bit from "../../assets/skills/bitbucket.png";
import mysql from "../../assets/skills/mysql.png";
import AnimatedPage from "../../components/Animations/AnimatedPage";


import html from "../../assets/skills/html.png";
import css from "../../assets/skills/css.png";
import node from "../../assets/skills/node-js.png";
import react from "../../assets/skills/react.png";
import express from "../../assets/skills/express.png";
import npm from "../../assets/skills/npm.png";
import yarn from "../../assets/skills/yarn.png";
import ts from "../../assets/skills/typescript.png";

import kubernetes from "../../assets/skills/kubernetes.png";
import devops from "../../assets/skills/devops.png";
import jira from "../../assets/skills/jira.png";
import postman from "../../assets/skills/postman.png";
import pycharm from "../../assets/skills/pycharm.png";
import sourcetree from "../../assets/skills/sourcetree.png";
import vscode from "../../assets/skills/vscode.png";
import intelli from "../../assets/skills/intelli.png";

const Home = () => {
  return (
    <>
      <AnimatedPage>
        <Hero />
      </AnimatedPage>
      <Skills
        skillsImage={skillsImg1}
        mainHeading="What I do?"
        skillsHeading="Software Development & Microservice"
        techImage1={python}
        techImage2={dj}
        techImage3={js}
        techImage4={golang}
        techImage5={sql}
        techImage6={rabbit}
        techImage7={kafka}
        techImage8={api}
        skillName1="Python"
        skillName2="Django"
        skillName3="JavaScript"
        skillName4="GoLang"
        skillName5="SQL"
        skillName6="RabbitMQ"
        skillName7="Kafka"
        skillName8="API"
        desc1="Expert in building scalable and reliable microservices."
        desc2="Proficient in Python, Django, Node.js, Express.js, TypeScript, and React.js."
        desc3="Extensive experience with AWS, Docker, and other cloud solutions."
        desc4="Adept at improving system performance and reducing downtime."
      />
      <Skills
        skillsImage={skillsImg3}
        skillsHeading="Full Stack Web Development"
        techImage1={html}
        techImage2={css}
        techImage3={react}
        techImage4={node}
        techImage5={express}
        techImage6={ts}
        techImage7={npm}
        techImage8={yarn}
        skillName1="HTML"
        skillName2="CSS"
        skillName3="React"
        skillName4="Node"
        skillName5="Express"
        skillName6="TypeScript"
        skillName7="NPM"
        skillName8="Yarn"
        desc1="Skilled in developing both front-end and back-end applications."
        desc2="Expertise in React.js, HTML, CSS3, and JavaScript."
        desc3="Proven ability to design, develop, and deploy full-stack web applications."
        desc4="Strong problem-solving and debugging skills."
      />
      <Skills
        skillsImage={skillsImg2}
        skillsHeading="Cloud Infra-Architecture"
        techImage1={mysql}
        techImage2={aws}
        techImage3={postgre}
        techImage4={mongo}
        techImage5={docker}
        techImage6={git}
        techImage7={bit}
        techImage8={gitlab}
        skillName1="MySQL"
        skillName2="AWS"
        skillName3="PostGreSQL"
        skillName4="MongoDB"
        skillName5="Docker"
        skillName6="Git"
        skillName7="BitBucket"
        skillName8="GitLab"
        desc1="Specialized in designing and managing cloud infrastructure."
        desc2="Experience with AWS, GCP, DigitalOcean, and Terraform."
        desc3="Implementing CI/CD pipelines with Jenkins and Docker."
        desc4="Ensuring high availability and reliability of cloud systems."
      />
      <Skills
        skillsImage={skillsImg4}
        skillsHeading="Tools"
        techImage1={vscode}
        techImage2={pycharm}
        techImage3={postman}
        techImage4={kubernetes}
        techImage5={devops}
        techImage6={sourcetree}
        techImage7={intelli}
        techImage8={jira}
        skillName1="VSCode"
        skillName2="PyCharm"
        skillName3="Postman"
        skillName4="Kubernetes"
        skillName5="Devops"
        skillName6="SourceTree"
        skillName7="Intelli J"
        skillName8="Jira"
        desc1="Proficient in a variety of development tools and technologies."
        desc2="Expertise in using Jira, Postman, Git, and more."
        desc3="Skilled in implementing DevOps practices for seamless integration and deployment."
        desc4="Strong focus on maintaining high code quality and performance."
      />
    </>
  );
};

export default Home;
