import React from "react";
import { data3 } from "../../data/Data3";
import Reveal3 from "../Animations/Reveal3";
import Reveal2 from "../Animations/Reveal2";

function Pubs() {
  return (
    <>
      <Reveal3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-8">
          {data3.map((item, index) => (
            <a
              key={index}
              href={item.link}
              target="target_blank"
              className="m-4 bg-[#17252a] p-8 rounded-lg hover:scale-105 ease-out duration-300 shadow-lg shadow-[#17252a]"
            >
              <Reveal2>
                <h1 className="flex items-center text-2xl lg:text-3xl text-[#def2f1] font-bold my-8">
                  <span className="mr-2"></span>
                  {item.name}
                </h1>
                <p className="my-4 px-2 text-[#3aafa9] text-xl">{item.text}</p>
                <p className="text-[#2b7a78] font-bold tracking-widest px-2 italic mt-8">
                  <span>Published on: </span>
                  {item.date}
                </p>
              </Reveal2>
            </a>
          ))}
        </div>
      </Reveal3>
    </>
  );
}

export default Pubs;
