import React from "react";
import AnimatedPage from "../Animations/AnimatedPage";
import proHero from "../../assets/projects/typing.png";
import Project from "./Project";

const ProHero = () => {
  return (
    <>
      <AnimatedPage>
        {/* parent div */}
        <div className="w-full h-screen">
          {/* container div */}
          <div className="max-w-[1730px] w-full h-full mx-auto">
            {/* hero */}
            <div className="grid grid-cols-1 lg:grid-cols-2 items-center my-20">
              <div className="col-span-1 m-auto px-8 sm:px-0">
                <img src={proHero} alt="" />
              </div>
              <div className="col-span-1">
                <h1 className="text-center font-semibold my-8 text-4xl sm:text-5xl lg:text-6xl text-[#def2f1]">
                  Projects
                </h1>
                <p className="text-center my-8 text-xl lg:text-3xl text-[#17252a] tracking-wider px-8 lg:px-0">
                  Explore the diverse selection of projects that I headed in
                  professional environments, working with teams and using
                  multiple technologies such as Python, Django, ExpressJS,
                  MongoDB, Docker, AWS and many more.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Project />
      </AnimatedPage>
    </>
  );
};

export default ProHero;
