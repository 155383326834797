import React from "react";
import AnimatedPage from "../Animations/AnimatedPage";
import openHero from "../../assets/projects/web-programming.png";
import Repos from "./Repos";
import Pubs from "./Pubs";

export default function Open() {
  return (
    <div>
      <AnimatedPage>
        {/* parent div */}
        <div className="w-full h-screen">
          {/* container div */}
          <div className="max-w-[1730px] w-full h-full mx-auto">
            {/* hero */}
            <div className="grid grid-cols-1 lg:grid-cols-2 items-center my-20">
              <div className="col-span-1 m-auto px-8 sm:px-0">
                <img src={openHero} alt="" />
              </div>
              <div className="col-span-1">
                <h1 className="text-center font-semibold my-16 text-4xl sm:text-5xl lg:text-6xl text-[#def2f1]">
                  Open Source
                </h1>
                <p className="text-center my-8 text-xl lg:text-3xl text-[#17252a] tracking-wider px-8 lg:px-0">
                  See all the open source contributions I made that includes the
                  github repositories of golang, python, django based projects
                  and some of my published articles.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Repositories */}
        <div className="my-24">
          <h1 className="text-center font-semibold my-8 text-4xl sm:text-5xl lg:text-6xl text-[#def2f1]">
            Open Source Projects
          </h1>
          <p className="text-center my-8 text-xl lg:text-3xl text-[#17252a] tracking-wider px-8 lg:px-0">
            Some of my published articles
          </p>
        </div>
        <Repos />

        {/* button for more projects */}
        <div className="max-w-[240px] mx-auto py-16 lg:py-8 ">
          <a
            href="https://github.com/nayonacademy?tab=repositories"
            target="target_blank"
          >
            <div className="w-full">
              <button className="flex mx-auto py-4 px-10 my-4 justify-center items-center bg-[#17252a] text-xl tracking-widest ease-out duration-300 text-[#feffff] rounded-md hover:bg-[#3aafa9] border-2 hover:text-[#17252a] border-[#17252a]">
                More Projects
              </button>
            </div>
          </a>
        </div>

        {/* Publications */}
        <div className="my-24">
          <h1 className="text-center font-semibold my-8 text-4xl sm:text-5xl lg:text-6xl text-[#def2f1]">
            Publications
          </h1>
          <p className="text-center my-8 text-xl lg:text-3xl text-[#17252a] tracking-wider px-8 lg:px-0">
            Some of my published articles
          </p>
        </div>
        <Pubs />
      </AnimatedPage>
    </div>
  );
}
