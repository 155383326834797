// Add publications below like the following key-value pairs

export const data3 = [
  {
    id: 1,
    name: "Running Llama Model with Ollama",
    text: "In the world of natural language processing (NLP), the LLama model...",
    link: "https://www.linkedin.com/pulse/running-llama-model-ollama-docker-container-simple-guide-islam-bxedc?trackingId=Gn72wo6qSnWGoPFdoCarWA%3D%3D&lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_recent_activity_content_view%3BgxpCJo2JTNS7FcgqUW7zFQ%3D%3D",
    date: "March 21, 2024",
  },
  {
    id: 2,
    name: "Getting Started with RabbitMQ and Django: A Practical Guide",
    text: "Benefits of Using RabbitMQ with Django: Improved Scalability: Handle...",
    link: "https://www.linkedin.com/pulse/getting-started-rabbitmq-django-practical-guide-shariful-islam-9irlc?trackingId=Gn72wo6qSnWGoPFdoCarWA%3D%3D&lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_recent_activity_content_view%3BPsnksNEfRE%2B6McyX0seZeQ%3D%3D",
    date: "December 27, 2023",
  },
  {
    id: 3,
    name: "Building a Real-Time Chat Application with Django",
    text: "In today's digital age, real-time communication is essential. If you're...",
    link: "https://www.linkedin.com/pulse/building-real-time-chat-application-django-beginner-shariful-islam?trackingId=Gn72wo6qSnWGoPFdoCarWA%3D%3D&lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_recent_activity_content_view%3BPsnksNEfRE%2B6McyX0seZeQ%3D%3D",
    date: "September 27, 2023",
  },
  {
    id: 4,
    name: "Object-Oriented Programming (OOP) Principles in Python",
    text: "Object-Oriented Programming (OOP) is a programming paradigm that is...",
    link: "https://www.linkedin.com/pulse/object-oriented-programming-oop-principles-python-shariful-islam?trackingId=Gn72wo6qSnWGoPFdoCarWA%3D%3D&lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_recent_activity_content_view%3B1%2BbN66IrTL6t7M1%2Fyoc%2FPA%3D%3D",
    date: "March 21, 2024",
  },
  {
    id: 5,
    name: "OOP Principles in Golang",
    text: "The object-oriented programming paradigm has 4 core principles...",
    link: "https://www.linkedin.com/pulse/oop-principles-golang-shariful-islam?trackingId=Gn72wo6qSnWGoPFdoCarWA%3D%3D&lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_recent_activity_content_view%3B1%2BbN66IrTL6t7M1%2Fyoc%2FPA%3D%3D",
    date: "December 27, 2023",
  },
  {
    id: 6,
    name: "Golang database seeding abstraction for Gorm",
    text: "Gorm is one of the most feature-rich ORMs for Go that I've had the pleasure...",
    link: "https://www.linkedin.com/pulse/golang-database-seeding-abstraction-gorm-shariful-islam?trackingId=Gn72wo6qSnWGoPFdoCarWA%3D%3D&lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_recent_activity_content_view%3B1%2BbN66IrTL6t7M1%2Fyoc%2FPA%3D%3D",
    date: "January 19, 2023",
  },
];
